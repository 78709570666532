import React from 'react'
import ReactLoading from 'react-loading';

const Loader = (props) => {
  return (
    props.loader ? 
    <div align="center">
        <ReactLoading type={"spin"} color={"black"} height={'4%'} width={'4%'} />
    </div> : ""
  )
}

export default Loader